import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { NewProfile } from "../../dtos";
import { client } from "../../shared";
import useLocations from "../../shared/useLocations";


const NewStudentButton = () => {
    const history = useHistory();
    useLocations();

    const handleClick = async () => {
        const r = await client.post(new NewProfile({
            profileType: 'Student',
        }));
        history.push(`/staff/${r.profileId}`)
    }

    return(
        <Button onClick={handleClick}>Add New Student</Button>
    );
}

export default NewStudentButton;