import * as React from 'react';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { P6Details, SetProfile } from "../../dtos";
import { client } from '../../shared';
import { SSFormErrors, SSTextField } from '../SSForms';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


interface P6DetailsFormProps{
    profileId: number,
    details: P6Details,
    profileType: string,
    onDone(changed:boolean):void
}

export const P6DetailsForm = ({details, profileId, profileType, onDone}: P6DetailsFormProps) =>{
    const classes = useStyles();
    const [responseStatus, setResponseStatus] = React.useState(null);

    const [dto, setDto] = React.useState(details);

    const submit = async () => {
        try {
            setResponseStatus(null);

            await client.put(new SetProfile({
                profileId: profileId,
                p6Details: dto
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value ?? ''
        }));
    }
    const handleNullableNumberChange = (field:string, value:string) => {
        var n = Number(value);
        setDto(o => ({
            ...o,
            [field]:n ? n:undefined
        }));
    }

    return (
        <>
            <Typography component="h4" variant="h4">Park 6 Details</Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSTextField id="p6Username" value={dto.p6Username} label="Park 6 Username" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6LegalName" value={dto.p6LegalName} label="Park 6 Legal Name" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6AdGuid" value={dto.p6AdGuid} label="AD Guid" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6GsuiteGuid" value={dto.p6GsuiteGuid} label="Gsuite Guid" onChange={handleTextChange} responseStatus={responseStatus}/>

                    {profileType === 'Student'? <>
                    <SSTextField id="p6Class" value={dto.p6Class ? dto.p6Class.toString():''} label="Graduating Class" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6StudentNumber" value={dto.p6StudentNumber ? dto.p6StudentNumber.toString():''} label="Student Number" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6StudentDcid" value={dto.p6StudentDcid ? dto.p6StudentDcid.toString():''} label="Student Dcid" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6PsWebUser" value={dto.p6PsWebUser} label="PS Web User" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6PsWebPass" value={dto.p6PsWebPass} label="PS Web Pass" onChange={handleTextChange} responseStatus={responseStatus}/>
                    </>:null}

                    {profileType === 'Staff'? <>
                    <SSTextField id="p6StaffDcid" value={dto.p6StaffDcid ? dto.p6StaffDcid.toString():''} label="Staff Dcid" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                    <SSTextField id="p6EmployeeId" value={dto.p6EmployeeId} label="P6 Employee Id" onChange={handleTextChange} responseStatus={responseStatus}/>
                    </>:null}
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={() => onDone(false)}>Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}