import { Grid, TextField } from "@material-ui/core";
import { ResponseStatus } from "./ResponseStatus";


interface SSDatePickerProps{
    id: string,
    label: string,
    value: string | undefined,
    required?: boolean,
    responseStatus?: ResponseStatus,
    onChange(field:string, value:string): void
}
export const SSDatePicker = ({id, label, value, onChange, required, responseStatus}:SSDatePickerProps) => {
    let hasError=false;
    let error='';
    if (responseStatus){
        for(let i=0; i < responseStatus.errors.length; i++){
            const x = responseStatus.errors[i];
            if(x.meta.PropertyName.toLowerCase() === id.toLowerCase()){
                hasError=true;
                error=x.message;
                break;
            }
        }
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        onChange(id, v ?? '');
    }
    return(
        <Grid item xs={12}>
        <TextField 
            variant="filled"
            type= "date"
            id={id}
            name={id}
            label={label}
            value={value}
            error = {hasError}
            helperText = {error}
            onChange = {handleChange}
            required = {required}
            fullWidth
            InputLabelProps={{
                shrink: true,
            }}
        />
        </Grid>
    );
}