import * as React from 'react';

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ProfileOverview } from '../Profiles/ProfileOverview';
import StaffLocationList from './StaffLocationList';
import { StaffOverview } from './StaffOverview';

const Staff = () => {
    const route = useRouteMatch();
    console.log(route.path, route.url);
    return(
        <Switch>
            <Route exact path={`${route.path}/byloc/:id`} component={StaffLocationList} />
            <Route path={`${route.path}/:profileId`} component={ProfileOverview} />
            <Route component={StaffOverview}/>
        </Switch>
    );
}

export default Staff