import { Button, Card, CardContent, CardHeader, Grid, makeStyles, Typography } from '@material-ui/core';
import { Mugshot, SelectMugshot } from '../../dtos';
import { client } from '../../shared';

const useStyles = makeStyles({
    table: {
        minWidth: 350,
    },
    newButton: {
        marginBottom: '5px'
    },
    mugshot: {

    },
});


interface MugshotListProps {
    mugshots: Mugshot[],
    selectedMugshotId: number,
    onDone(changed: boolean): void
}

export const MugshotList = ({ mugshots, selectedMugshotId, onDone }: MugshotListProps) => {
    const classes = useStyles();

    const selectMugshot = async (mugshotId: number) => {
        try {
            await client.put(new SelectMugshot({
                mugshotId: mugshotId
            }));
            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
        }
    }


    return (
        <div>
            <Button variant="text" color="secondary" onClick={() => onDone(false)}>Return to Profile</Button>
            {mugshots.map((m) => (
                <Card variant="outlined" key={m.mugshotId}>
                    <CardHeader title={m.description} />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={4}>
                                <a target="_blank" rel="noreferrer" href={m.url}><img src={m.thumbnailUrl} height={120} className={classes.mugshot} alt="Mugshot"/></a>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography variant="h5">{m.photographer}</Typography>
                                <p>{m.dateTaken}</p>
                                {m.mugshotId === selectedMugshotId ? 
                                    "Selected" : 
                                    <Button variant="outlined" color="primary" onClick={(e)=>selectMugshot(m.mugshotId)}>Select</Button>
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            ))}

        </div>
    );
}