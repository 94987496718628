import { JsonServiceClient, UserAttributes, IAuthSession } from '@servicestack/client';
import { createContext, useReducer } from 'react';
import { Authenticate, AuthenticateResponse } from '../dtos';

//export let client = new JsonServiceClient('https://localhost:5001/');
export let client = new JsonServiceClient('https://amoapi.azurewebsites.net/');

export enum Routes {
    Home = '/',
    About = '/about',
    SignIn = '/signin',
    SignUp = '/signup',
    Profile = '/profile',
    Locations = '/locations',
    StaffAssignmentTypes = '/staff-assignment-types',
    Admin = '/admin',
    Forbidden = '/forbidden',

    Students = '/students',
    Staff = '/staff',
  }
  
export enum Roles {
    Admin = 'Admin',
}

export const getTodayAsString = () => new Date().toISOString().substring(0,10);

export {
    errorResponse, errorResponseExcept,
    splitOnFirst, toPascalCase,
    queryString,
    classNames,
  } from '@servicestack/client';

//state
interface State {
    userSession: IAuthSession | null;
    userAttributes?: string[];
    roles?: string[];
    permissions?: string[];
}
interface Action {
    type: 'signout' | 'signin'
    data?: AuthenticateResponse | any
}

const initialState: State = {
    userSession: null,
    userAttributes: undefined,
};

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'signin':
        const userSession = action.data as IAuthSession;
        return { ...state, userSession, roles: userSession.roles || [], permissions: userSession.permissions || [],
                    userAttributes: UserAttributes.fromSession(userSession) } as State;
        case 'signout':
        return { userSession:null } as State;
        default:
        throw new Error();
    }
}

interface Context {
    state: State,
    dispatch: React.Dispatch<Action>
}

export const StateContext = createContext({} as Context);

export const StateProvider = (props: any) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (<StateContext.Provider value={{ state, dispatch }}>{props.children}</StateContext.Provider>);
}

type Dispatch = React.Dispatch<Action>;

export const checkAuth = async (dispatch: Dispatch) => {
    try {
        dispatch({ type: 'signin', data: await client.post(new Authenticate()) });
    } catch (e) {
        dispatch({ type: 'signout' });
    }
};

export const signout = async (dispatch: Dispatch) => {
    dispatch({ type: 'signout' });
    await client.post(new Authenticate({ provider: 'logout' }));
};
