import { MenuItem } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { GetStaffAssignmentTypes, StaffAssignmentType } from '../../dtos';
import { client } from '../../shared';
import SSSelect from '../SSForms/SSSelect';

interface StaffAssignmentTypeSelectProps{
    assignmentTypeId: number,
    onChange(fildName:string, value:number):void
}

export const StaffAssignmentTypeSelect = ({assignmentTypeId, onChange}:StaffAssignmentTypeSelectProps) => {
    const [assignmentTypes, setAssignmentTypes] = useState(new Array<StaffAssignmentType>())

    React.useEffect(() => {
        (async () => {
            setAssignmentTypes((await client.get(new GetStaffAssignmentTypes())).staffAssignmentTypes);
        })();
    }, []); // fires once

    if (assignmentTypes.length === 0) return null;

    const handleChange = (field: string, newValue: number ) => {
        onChange(field, newValue);
    }

    return (
        <SSSelect id="staffAssignmentTypeId" label="Staff Assignment Type" value={assignmentTypeId} onChange={handleChange}>
            {assignmentTypes.map((t)=>(
                <MenuItem key={t.staffAssignmentTypeId} value={t.staffAssignmentTypeId}>{t.name}</MenuItem>
            ))}
        </SSSelect>
    );
}