import * as React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { client, Routes, classNames } from '../../shared';
import { GetLocation, SetLocation, Location } from '../../dtos';
import { Grid, Button, Typography, makeStyles, Link } from '@material-ui/core';
import { SSCheckBox, SSFormErrors, SSTextField } from '../SSForms';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export const SetLocationForm = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);

    const history = useHistory();
    const { id } = useParams<{id:string}>();
    
    console.log(`id = ${id}`);

    const [dto, setDto] = useState<Location>({locationId:0, code:'', name:'',isSchool:true})

    React.useEffect(() => {
        if(id!==undefined){
            (async () => {
                var l = (await client.get(new GetLocation({locationId:Number(id)}))).location;
                setDto(l);
            })();
        }
    }, [id]); // fires once

    const submit = async () => {
        try {
            setLoading(true);
            setResponseStatus(null);

            await client.put(new SetLocation({
                location:dto
            }));

            setLoading(false);
            history.push(Routes.Locations);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
            setLoading(false);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value
        }));
    }
    const handleCheckboxChange = (field:string, value:boolean) => {
        setDto(x => ({
            ...x,
            [field]:value
        }));
    }
    const handleNullableNumberChange = (field:string, value:string) => {
        setDto(o => ({
            ...o,
            [field]:value==='' ? undefined : Number(value)
        }));
    }


    return (
        <>
            <Typography component="h1" variant="h2">Set Location Data</Typography>

            <form className={classNames({error:responseStatus, loading})} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSTextField id="code" value={dto.code} responseStatus={responseStatus} placeholder="Code" label="Code" onChange={handleTextChange} />
                    <SSTextField id="name" value={dto.name} responseStatus={responseStatus} placeholder="Name" label="Name" onChange={handleTextChange} />
                    <SSCheckBox id="isSchool" checked={dto.isSchool} responseStatus={responseStatus} label="Is School" onChange={handleCheckboxChange} />
                    <SSTextField id="powerSchoolId" value={`${dto.powerSchoolId}`} responseStatus={responseStatus} placeholder="Powerschool Id" label="Powerschool Id" onChange={handleNullableNumberChange} />
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Link href={Routes.Locations} variant="body2">Back to Locations</Link>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}