import * as React from 'react';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { DeactivateProfile } from "../../dtos";
import { client, getTodayAsString } from '../../shared';
import { SSFormErrors, SSTextField } from '../SSForms';
import { SSDatePicker } from '../SSForms/SSDatePicker';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


interface DeactivateProfileFormProps{
    profileId: number,
    endDate?: string,
    endNote?: string
    onDone(changed:boolean):void
}

export const DeactivateProfileForm = ({profileId, endDate, endNote, onDone}: DeactivateProfileFormProps) =>{
    const classes = useStyles();
    const [responseStatus, setResponseStatus] = React.useState(null);

    const [dto, setDto] = React.useState({profileId, endDate: endDate ?? getTodayAsString(), endNote: endNote ?? ''});

    const submit = async () => {
        try {
            setResponseStatus(null);

            await client.post(new DeactivateProfile({
                profileId: dto.profileId,
                endDate: dto.endDate,
                endNote: dto.endNote,
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value ?? ''
        }));
    }

    return (
        <>
            <Typography component="h3" variant="h3">Set Profile Details</Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSDatePicker id="endDate" label="End Date" value={dto.endDate} onChange={handleTextChange} />
                    <SSTextField id="endNote" value={dto.endNote} label="End Note" onChange={handleTextChange} responseStatus={responseStatus}/>
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={() => onDone(false)}>Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}