import { Card, CardActionArea, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import { P6Details } from '../../dtos';

const useStyles = makeStyles({
    missing: {
        color: 'red'
    },
  });

interface P6DetailsCardProps{
    details: P6Details,
    profileType: string,
    onClick():void
}
export const P6DetailsCard = ({details, profileType, onClick}:P6DetailsCardProps) => {
    const classes = useStyles();
    const d = details;
    const f = (name: string, value:any, missing:boolean) => missing ? <Typography className={classes.missing}>{name}:</Typography> : <Typography>{name}: {value}</Typography>;
    return(
        <Card variant="outlined">
            <CardActionArea onClick={onClick}>
                <CardHeader title="Park 6"/>
                <CardContent>
                    {f("Username", d.p6Username, d.p6Username==='')}
                    {profileType === 'Staff'? <>
                        {f("StaffDcid", d.p6StaffDcid, d.p6StaffDcid === undefined)}
                        {f("EmployeeID", d.p6EmployeeId, d.p6EmployeeId === '')}
                    </>:null}
                    {profileType === 'Student'? <>
                        {f("ClassOf", d.p6Class, d.p6Class === undefined)}
                        {f("StudentDcid", d.p6StudentDcid, d.p6StudentDcid === undefined)}
                        {f("StudentNumber", d.p6StudentNumber, d.p6StudentNumber === undefined)}
                        {f("Creds", d.p6PsWebUser+d.p6PsWebPass, d.p6PsWebUser === '')}
                    </>:null}
                </CardContent>
            </CardActionArea>
        </Card>
    );
}