import * as React from 'react';

interface AboutProps{
    message: string
}

export const About = (props:AboutProps) => (
    <div id="about">
        <div className="svg-users svg-8x ml-2"/>
        <h3>{props.message}</h3>
    </div>
);
