import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { StateProvider } from './shared';

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </StateProvider>
  </React.StrictMode>
  , document.getElementById('root')
);