import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { GetStudentsOverview, StudentsOverviewForSchool } from '../../dtos';
import { client } from '../../shared';
import NewStudentButton from './NewStudentButton';

const useStyles = makeStyles( {
    table:{
        minWidth: 350,
    },
});


export const StudentOverview = () => {
    const classes = useStyles();
    const [dtos, setDtos] = React.useState(new Array<StudentsOverviewForSchool>())

    React.useEffect(() => {
        (async () => {
            setDtos((await client.get(new GetStudentsOverview())).overviewsBySchool);
        })();
    }, []); // fires once
    
    return(
<div>
    <Typography variant="h2">Students by School</Typography>
    <NewStudentButton/>
    <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Location Table">
            <TableHead>
                <TableRow>
                    <TableCell>School</TableCell>
                    <TableCell>Students</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dtos.map((l) => (
                    <TableRow key={l.schoolId}>
                        <TableCell>{l.schoolName}</TableCell>
                        <TableCell>{l.studentCount}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
</div>
)};