import * as React from 'react';

import { Route, Switch } from "react-router-dom";
import { LocationsOverview } from "./LocationsOverview";
import { SetLocationForm } from "./SetLocationForm";

const Locations = () => {
    return(
        <Switch>
            <Route path='/locations/:id/edit' component={SetLocationForm} />
            <Route path='/locations/new' component={SetLocationForm} />
            <Route component={LocationsOverview}/>
        </Switch>
    );
}

export default Locations