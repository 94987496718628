import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProfileSearch, ProfileSearchResponse } from "../../dtos";
import useQuery from "../../hooks/useQuery";
import { client } from "../../shared";

const ProfileSearchScreen = () =>{
    const query = useQuery().get("q") ?? '';

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(null as unknown as ProfileSearchResponse);

    useEffect(() => {
        async function search() {
            setLoading(true);
            setResult( await client.post(new ProfileSearch({ query })));
            setLoading(false);
        }

        search();
    }, [query]);

    if (loading) {
        return (
            <div className="row" >
                <div className="col-4">
                    Searching for: {query}
                </div>
            </div >
        );
    }

    return (
        <div>
            <h3>Search: {query}</h3>
            Results: { result.searchResults.length}
            <ul>
                {result.searchResults.map(({ profileId, fullName, mugshotUrl, isActive, description,profileType }) => (
                    <li key={profileId}>
                        <img src={mugshotUrl ? mugshotUrl : '/nomug.jpg'} className="img-thumbnail" width="50" alt="Mugshot" />
                        <Link to={'/staff/' + profileId}> {fullName}</Link>
                        <span> {isActive ? '':'Former '}{profileType} {description}</span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ProfileSearchScreen;