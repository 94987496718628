import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const ProfileSearchBox = () => {
    const [qry, setQry] = useState('');
    const history = useHistory();

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => setQry(event.target.value);
    const handleQueryKey = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            history.push({ pathname: '/search', search: '?' + new URLSearchParams({ q: qry }).toString() });
            setQry('');
        }
    }
    return(
        <TextField type="search" placeholder="Search" aria-label="Search" value={qry} onChange={handleQueryChange} onKeyPress={handleQueryKey} />
    );
}

export default ProfileSearchBox;