import { useEffect } from 'react';
import { useState } from 'react';
import { client } from '.';
import { GetLocations, Location } from '../dtos';

export let Locations:(Location[]) = [];
export let LocationsDict:{[id:number] : Location} = {};

const useLocations = ():[string] => {
    const [status, setStatus] = useState('idle');
    //const [locations, setLocations] = useState<Location[]>([])

    useEffect(() => {
        (async () => {
            setStatus('fetching');
            if (Locations.length === 0){
                console.log("Getting locations from Server");
                Locations = (await client.get(new GetLocations())).locations;
                Locations.forEach(l=>LocationsDict[l.locationId]=l)
            }
            //setLocations(cachedLocations as Location[]);
            setStatus('fetched');
    })();
    }, [])

    return [ status ];
}

export default useLocations;