/* Options:
Date: 2021-09-17 18:34:27
Version: 5.120
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:5001

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IGet
{
}

export class P6Account
{
    public profileId: number;
    public p6Username: string;
    public type: string;
    public name: string;
    public surname: string;
    public grade?: number;
    public staffTitle: string;
    public locationCode: string;
    public p6GsuiteGuid: string;
    public p6AdGuid: string;
    public isActive: boolean;

    public constructor(init?: Partial<P6Account>) { (Object as any).assign(this, init); }
}

export class CanMembership
{
    public canMembershipId: number;
    public profileId: number;
    public hasPermissionForm: boolean;
    public disjoinType: string;
    public scannedContractId?: number;
    public scannedPermissionFormId?: number;
    public note: string;
    public isActive: boolean;
    public startDate: string;
    public startNote: string;
    public endDate?: string;
    public endNote: string;

    public constructor(init?: Partial<CanMembership>) { (Object as any).assign(this, init); }
}

export class Attachment
{
    public attachmentId: number;
    public profileId: number;
    public effectiveDate: string;
    public url: string;
    public fileNumber?: number;
    public title: string;
    public type: string;
    public note: string;

    public constructor(init?: Partial<Attachment>) { (Object as any).assign(this, init); }
}

export class Location
{
    public locationId: number;
    public code: string;
    public name: string;
    public isSchool: boolean;
    public powerSchoolId?: number;

    public constructor(init?: Partial<Location>) { (Object as any).assign(this, init); }
}

export class Mugshot
{
    public mugshotId: number;
    public profileId: number;
    public url: string;
    public thumbnailUrl: string;
    public photographer: string;
    public description: string;
    public dateTaken: string;
    public gradeWhenTaken?: number;

    public constructor(init?: Partial<Mugshot>) { (Object as any).assign(this, init); }
}

export class ProfileDetails
{
    public name: string;
    public surname: string;
    public honorific: string;
    public fullName: string;
    public sex: string;
    public birthMonth?: number;
    public birthDay?: number;

    public constructor(init?: Partial<ProfileDetails>) { (Object as any).assign(this, init); }
}

export class CanDetails
{
    public canStartDate?: string;
    public isCanMember: boolean;

    public constructor(init?: Partial<CanDetails>) { (Object as any).assign(this, init); }
}

export class P6Details
{
    public p6Username: string;
    public p6AdGuid: string;
    public p6GsuiteGuid: string;
    public p6Class?: number;
    public p6StudentNumber?: number;
    public p6StudentDcid?: number;
    public p6PsWebUser: string;
    public p6PsWebPass: string;
    public p6StaffDcid?: number;
    public p6EmployeeId: string;
    public p6LegalName: string;

    public constructor(init?: Partial<P6Details>) { (Object as any).assign(this, init); }
}

export class StaffAssignment
{
    public staffAssignmentId: number;
    public locationId: number;
    public staffAssignmentTypeId: number;
    public title: string;
    public certified: boolean;
    public sped: boolean;
    public contracted: boolean;
    public note: string;
    public room: string;
    public extension: string;
    public directPhone: string;
    public sortPriority: number;
    public isActive: boolean;
    public startDate: string;
    public startNote: string;
    public endDate?: string;
    public endNote: string;

    public constructor(init?: Partial<StaffAssignment>) { (Object as any).assign(this, init); }
}

export class StudentEnrollment
{
    public studentEnrollmentId: number;
    public profileId: number;
    public locationId: number;
    public grade: number;
    public code: string;
    public isActive: boolean;
    public startDate: string;
    public startNote: string;
    public endDate?: string;
    public endNote: string;

    public constructor(init?: Partial<StudentEnrollment>) { (Object as any).assign(this, init); }
}

export class StaffAssignmentType
{
    public staffAssignmentTypeId: number;
    public name: string;
    public sortOrder: number;

    public constructor(init?: Partial<StaffAssignmentType>) { (Object as any).assign(this, init); }
}

export class StudentIdCard
{
    public studentIdCardId: number;
    public profileId: number;
    public issueDate: string;
    public issueReason: string;
    public issueNote: string;
    public name: string;
    public surname: string;
    public grade: number;
    public locationId: number;
    public mugshotId: number;
    public isCanCard: boolean;
    public rfidCode: number;

    public constructor(init?: Partial<StudentIdCard>) { (Object as any).assign(this, init); }
}

export class StudentSchedule
{
    public studentScheduleId: string;
    public expression: string;
    public courseName: string;
    public room: string;
    public period: number;
    public periodicity: string;
    public studentProfileId: number;
    public staffProfileId: number;
    public teacherSurname: string;

    public constructor(init?: Partial<StudentSchedule>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class BaseResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BaseResponse>) { (Object as any).assign(this, init); }
}

export class ProfileSearchResult
{
    public profileId: number;
    public profileType: string;
    public name: string;
    public surname: string;
    public fullName: string;
    public description: string;
    public isActive: boolean;
    public isCan: boolean;
    public mugshotUrl: string;
    public staffTitle: string;
    public grade?: number;
    public locationId: number;
    public locationCode: string;
    public dcid?: number;

    public constructor(init?: Partial<ProfileSearchResult>) { (Object as any).assign(this, init); }
}

export class Profile
{
    public profileId: number;
    public profileType: string;
    public isActive: boolean;
    public endDate?: string;
    public endNote: string;
    public grade?: number;
    public staffTitle: string;
    public locationCode: string;
    public locationId: number;
    public canMembershipId?: number;
    public studentEnrollmentId?: number;
    public staffAssignmentId?: number;
    public details: ProfileDetails;
    public canDetails: CanDetails;
    public p6Details: P6Details;
    public mugshot: Mugshot;
    public staffAssignment: StaffAssignment;
    public studentEnrollment: StudentEnrollment;
    public canMembership: CanMembership;
    public studentIdCard: StudentIdCard;
    public mugshots: Mugshot[];
    public staffAssignments: StaffAssignment[];
    public studentEnrollments: StudentEnrollment[];
    public canMemberships: CanMembership[];
    public studentSchedules: StudentSchedule[];

    public constructor(init?: Partial<Profile>) { (Object as any).assign(this, init); }
}

export class StaffAssignmentListGroupItem
{
    public groupName: string;
    public profileId: number;
    public name: string;
    public surname: string;
    public title: string;
    public mugshotThumbnail: string;
    public sortPriority: number;

    public constructor(init?: Partial<StaffAssignmentListGroupItem>) { (Object as any).assign(this, init); }
}

export class StaffAssignmentListGroup
{
    public name: string;
    public staff: StaffAssignmentListGroupItem[];

    public constructor(init?: Partial<StaffAssignmentListGroup>) { (Object as any).assign(this, init); }
}

export class StaffOverviewForLocation
{
    public locationId: number;
    public locationCode: string;
    public locationName: string;
    public staffCount: number;

    public constructor(init?: Partial<StaffOverviewForLocation>) { (Object as any).assign(this, init); }
}

export class StudentsOverviewForSchool
{
    public schoolId: number;
    public schoolCode: string;
    public schoolName: string;
    public studentCount: number;

    public constructor(init?: Partial<StudentsOverviewForSchool>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export class GetAccountsResponse extends BaseResponse
{
    public accounts: P6Account[];

    public constructor(init?: Partial<GetAccountsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetAccountResponse extends BaseResponse
{

    public constructor(init?: Partial<SetAccountResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetCanMembershipResponse extends BaseResponse
{
    public membership: CanMembership;

    public constructor(init?: Partial<SetCanMembershipResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DisjoinCanResponse extends BaseResponse
{

    public constructor(init?: Partial<DisjoinCanResponse>) { super(init); (Object as any).assign(this, init); }
}

export class UploadAttachmentResponse extends BaseResponse
{

    public constructor(init?: Partial<UploadAttachmentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetLocationsResponse extends BaseResponse
{
    public locations: Location[];

    public constructor(init?: Partial<GetLocationsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetLocationResponse extends BaseResponse
{
    public location: Location;

    public constructor(init?: Partial<GetLocationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetLocationResponse extends BaseResponse
{
    public location: Location;

    public constructor(init?: Partial<SetLocationResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetMugshotsResponse extends BaseResponse
{
    public mugshots: Mugshot[];

    public constructor(init?: Partial<GetMugshotsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SelectMugshotResponse extends BaseResponse
{

    public constructor(init?: Partial<SelectMugshotResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetMugshotResponse extends BaseResponse
{

    public constructor(init?: Partial<SetMugshotResponse>) { super(init); (Object as any).assign(this, init); }
}

export class AddMugshotResponse extends BaseResponse
{
    public mugshot: Mugshot;

    public constructor(init?: Partial<AddMugshotResponse>) { super(init); (Object as any).assign(this, init); }
}

export class HelloResponse
{
    public result: string;

    public constructor(init?: Partial<HelloResponse>) { (Object as any).assign(this, init); }
}

export class ProfileSearchResponse extends BaseResponse
{
    public searchResults: ProfileSearchResult[];

    public constructor(init?: Partial<ProfileSearchResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetProfileResponse extends BaseResponse
{
    public profile: Profile;

    public constructor(init?: Partial<GetProfileResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetProfileResponse extends BaseResponse
{

    public constructor(init?: Partial<SetProfileResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeactivateProfileResponse extends BaseResponse
{

    public constructor(init?: Partial<DeactivateProfileResponse>) { super(init); (Object as any).assign(this, init); }
}

export class NewProfileResponse extends BaseResponse
{
    public profileId: number;

    public constructor(init?: Partial<NewProfileResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetStaffAssignmentListResponse extends BaseResponse
{
    public name: string;
    public groups: StaffAssignmentListGroup[];

    public constructor(init?: Partial<GetStaffAssignmentListResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetStaffAssignmentTypesResponse extends BaseResponse
{
    public staffAssignmentTypes: StaffAssignmentType[];

    public constructor(init?: Partial<GetStaffAssignmentTypesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetStaffAssignmentTypeResponse extends BaseResponse
{
    public staffAssignmentType: StaffAssignmentType;

    public constructor(init?: Partial<GetStaffAssignmentTypeResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetStaffAssignmentTypeResponse extends BaseResponse
{
    public staffAssignmentType: StaffAssignmentType;

    public constructor(init?: Partial<SetStaffAssignmentTypeResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetStaffOverviewResponse extends BaseResponse
{
    public overviews: StaffOverviewForLocation[];

    public constructor(init?: Partial<GetStaffOverviewResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetStaffAssignmentResponse extends BaseResponse
{

    public constructor(init?: Partial<SetStaffAssignmentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SelectStaffAssignmentResponse extends BaseResponse
{

    public constructor(init?: Partial<SelectStaffAssignmentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class DeliverStudentIdCardsResponse extends BaseResponse
{

    public constructor(init?: Partial<DeliverStudentIdCardsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetStudentIdCardResponse extends BaseResponse
{

    public constructor(init?: Partial<SetStudentIdCardResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetStudentSchedulesResponse extends BaseResponse
{

    public constructor(init?: Partial<SetStudentSchedulesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class SetStudentEnrollmentResponse extends BaseResponse
{

    public constructor(init?: Partial<SetStudentEnrollmentResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetStudentProfilesResponse extends BaseResponse
{
    public profiles: Profile[];

    public constructor(init?: Partial<GetStudentProfilesResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetStudentsOverviewResponse extends BaseResponse
{
    public overviewsBySchool: StudentsOverviewForSchool[];

    public constructor(init?: Partial<GetStudentsOverviewResponse>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class GetAccounts implements IReturn<GetAccountsResponse>
{

    public constructor(init?: Partial<GetAccounts>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetAccountsResponse(); }
    public getTypeName() { return 'GetAccounts'; }
}

export class SetAccount implements IReturn<SetAccountResponse>
{
    public account: P6Account;
    public accounts: P6Account[];

    public constructor(init?: Partial<SetAccount>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetAccountResponse(); }
    public getTypeName() { return 'SetAccount'; }
}

export class SetCanMembership implements IReturn<SetCanMembershipResponse>
{
    public membership: CanMembership;

    public constructor(init?: Partial<SetCanMembership>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetCanMembershipResponse(); }
    public getTypeName() { return 'SetCanMembership'; }
}

export class DisjoinCan implements IReturn<DisjoinCanResponse>
{
    public profileId: number;
    public disjoinType: string;
    public endDate: string;
    public endNote: string;

    public constructor(init?: Partial<DisjoinCan>) { (Object as any).assign(this, init); }
    public createResponse() { return new DisjoinCanResponse(); }
    public getTypeName() { return 'DisjoinCan'; }
}

export class UploadAttachment implements IReturn<UploadAttachmentResponse>
{
    public attachment: Attachment;

    public constructor(init?: Partial<UploadAttachment>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadAttachmentResponse(); }
    public getTypeName() { return 'UploadAttachment'; }
}

export class GetLocations implements IReturn<GetLocationsResponse>
{
    public isSchool?: boolean;

    public constructor(init?: Partial<GetLocations>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetLocationsResponse(); }
    public getTypeName() { return 'GetLocations'; }
}

export class GetLocation implements IReturn<GetLocationResponse>
{
    public locationId: number;
    public code: string;

    public constructor(init?: Partial<GetLocation>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetLocationResponse(); }
    public getTypeName() { return 'GetLocation'; }
}

export class SetLocation implements IReturn<SetLocationResponse>
{
    public location: Location;

    public constructor(init?: Partial<SetLocation>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetLocationResponse(); }
    public getTypeName() { return 'SetLocation'; }
}

export class GetMugshots implements IReturn<GetMugshotsResponse>
{
    public profileId: number;

    public constructor(init?: Partial<GetMugshots>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetMugshotsResponse(); }
    public getTypeName() { return 'GetMugshots'; }
}

export class SelectMugshot implements IReturn<SelectMugshotResponse>
{
    public mugshotId: number;

    public constructor(init?: Partial<SelectMugshot>) { (Object as any).assign(this, init); }
    public createResponse() { return new SelectMugshotResponse(); }
    public getTypeName() { return 'SelectMugshot'; }
}

export class SetMugshot implements IReturn<SetMugshotResponse>
{
    public mugshot: Mugshot;

    public constructor(init?: Partial<SetMugshot>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetMugshotResponse(); }
    public getTypeName() { return 'SetMugshot'; }
}

export class AddMugshot implements IReturn<AddMugshotResponse>
{
    public profileId: number;
    public photographer: string;
    public description: string;

    public constructor(init?: Partial<AddMugshot>) { (Object as any).assign(this, init); }
    public createResponse() { return new AddMugshotResponse(); }
    public getTypeName() { return 'AddMugshot'; }
}

// @Route("/hello")
// @Route("/hello/{Name}")
export class Hello implements IReturn<HelloResponse>
{
    public name: string;

    public constructor(init?: Partial<Hello>) { (Object as any).assign(this, init); }
    public createResponse() { return new HelloResponse(); }
    public getTypeName() { return 'Hello'; }
}

export class ProfileSearch implements IReturn<ProfileSearchResponse>
{
    public query: string;
    public profileType: string;

    public constructor(init?: Partial<ProfileSearch>) { (Object as any).assign(this, init); }
    public createResponse() { return new ProfileSearchResponse(); }
    public getTypeName() { return 'ProfileSearch'; }
}

export class GetProfile implements IReturn<GetProfileResponse>
{
    public profileId: number;
    public includeSchedule: boolean;

    public constructor(init?: Partial<GetProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetProfileResponse(); }
    public getTypeName() { return 'GetProfile'; }
}

export class SetProfile implements IReturn<SetProfileResponse>
{
    public profileId: number;
    public profileDetails: ProfileDetails;
    public canDetails: CanDetails;
    public p6Details: P6Details;

    public constructor(init?: Partial<SetProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetProfileResponse(); }
    public getTypeName() { return 'SetProfile'; }
}

export class DeactivateProfile implements IReturn<DeactivateProfileResponse>
{
    public profileId: number;
    public endDate?: string;
    public endNote: string;

    public constructor(init?: Partial<DeactivateProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeactivateProfileResponse(); }
    public getTypeName() { return 'DeactivateProfile'; }
}

export class NewProfile implements IReturn<NewProfileResponse>
{
    public profileType: string;
    public details: ProfileDetails;
    public p6Details: P6Details;
    public assignment: StaffAssignment;
    public enrollment: StudentEnrollment;
    public startDate?: string;

    public constructor(init?: Partial<NewProfile>) { (Object as any).assign(this, init); }
    public createResponse() { return new NewProfileResponse(); }
    public getTypeName() { return 'NewProfile'; }
}

export class GetStaffAssignmentList implements IReturn<GetStaffAssignmentListResponse>
{
    public locationId: number;

    public constructor(init?: Partial<GetStaffAssignmentList>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStaffAssignmentListResponse(); }
    public getTypeName() { return 'GetStaffAssignmentList'; }
}

export class GetStaffAssignmentTypes implements IReturn<GetStaffAssignmentTypesResponse>
{

    public constructor(init?: Partial<GetStaffAssignmentTypes>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStaffAssignmentTypesResponse(); }
    public getTypeName() { return 'GetStaffAssignmentTypes'; }
}

export class GetStaffAssignmentType implements IReturn<GetStaffAssignmentTypeResponse>
{
    public staffAssignmentTypeId: number;

    public constructor(init?: Partial<GetStaffAssignmentType>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStaffAssignmentTypeResponse(); }
    public getTypeName() { return 'GetStaffAssignmentType'; }
}

export class SetStaffAssignmentType implements IReturn<SetStaffAssignmentTypeResponse>
{
    public staffAssignmentType: StaffAssignmentType;

    public constructor(init?: Partial<SetStaffAssignmentType>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetStaffAssignmentTypeResponse(); }
    public getTypeName() { return 'SetStaffAssignmentType'; }
}

export class GetStaffOverview implements IReturn<GetStaffOverviewResponse>
{

    public constructor(init?: Partial<GetStaffOverview>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStaffOverviewResponse(); }
    public getTypeName() { return 'GetStaffOverview'; }
}

export class SetStaffAssignment implements IReturn<SetStaffAssignmentResponse>
{
    public profileId: number;
    public assignment: StaffAssignment;
    public isTransfer: boolean;

    public constructor(init?: Partial<SetStaffAssignment>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetStaffAssignmentResponse(); }
    public getTypeName() { return 'SetStaffAssignment'; }
}

export class SelectStaffAssignment implements IReturn<SelectStaffAssignmentResponse>
{
    public staffAssignmentId: number;

    public constructor(init?: Partial<SelectStaffAssignment>) { (Object as any).assign(this, init); }
    public createResponse() { return new SelectStaffAssignmentResponse(); }
    public getTypeName() { return 'SelectStaffAssignment'; }
}

export class DeliverStudentIdCards implements IReturn<DeliverStudentIdCardsResponse>
{
    public cards: StudentIdCard[];

    public constructor(init?: Partial<DeliverStudentIdCards>) { (Object as any).assign(this, init); }
    public createResponse() { return new DeliverStudentIdCardsResponse(); }
    public getTypeName() { return 'DeliverStudentIdCards'; }
}

export class SetStudentIdCard implements IReturn<SetStudentIdCardResponse>
{
    public card: StudentIdCard;

    public constructor(init?: Partial<SetStudentIdCard>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetStudentIdCardResponse(); }
    public getTypeName() { return 'SetStudentIdCard'; }
}

export class SetStudentSchedules implements IReturn<SetStudentSchedulesResponse>
{
    public schedules: StudentSchedule[];

    public constructor(init?: Partial<SetStudentSchedules>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetStudentSchedulesResponse(); }
    public getTypeName() { return 'SetStudentSchedules'; }
}

export class SetStudentEnrollment implements IReturn<SetStudentEnrollmentResponse>
{
    public profileId: number;
    public enrollment: StudentEnrollment;

    public constructor(init?: Partial<SetStudentEnrollment>) { (Object as any).assign(this, init); }
    public createResponse() { return new SetStudentEnrollmentResponse(); }
    public getTypeName() { return 'SetStudentEnrollment'; }
}

export class GetStudentProfiles implements IReturn<GetStudentProfilesResponse>
{

    public constructor(init?: Partial<GetStudentProfiles>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStudentProfilesResponse(); }
    public getTypeName() { return 'GetStudentProfiles'; }
}

export class GetStudentsOverview implements IReturn<GetStudentsOverviewResponse>
{

    public constructor(init?: Partial<GetStudentsOverview>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetStudentsOverviewResponse(); }
    public getTypeName() { return 'GetStudentsOverview'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetApiKeysResponse(); }
    public getTypeName() { return 'GetApiKeys'; }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public createResponse() { return new RegenerateApiKeysResponse(); }
    public getTypeName() { return 'RegenerateApiKeys'; }
}

