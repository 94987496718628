import 'es6-shim';
import * as React from 'react';

import { Route, Switch } from 'react-router-dom';
import { Routes, StateContext, checkAuth } from './shared';
import { Fallback, Forbidden } from '@servicestack/react';

import { Home } from './components/Home';
import { About } from './components/About';
import { SignIn } from './components/SignIn';
import { Profile } from './components/Profile';
import { AppNavBar } from './components/AppNavBar';
import { StudentOverview } from './components/Students/StudentsOverview';
import Locations from './components/Locations/';
import StaffAssignmentTypes from './components/StaffAssignmentTypes';
import { Container, CssBaseline, makeStyles } from '@material-ui/core';
import Staff from './components/Staff';
import ProfileSearchScreen from './components/Search/ProfileSearchScreen';

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
}));


export const App = () => {
    const classes = useStyles();
    const { state, dispatch } = React.useContext(StateContext);

    React.useEffect(() => {
        (async () => {
            checkAuth(dispatch);
        })();
    }, [dispatch]); // fires once

    return (
        <div>
            <CssBaseline/>
            <AppNavBar/>

            <Container maxWidth="sm">
                <div className={classes.paper}>
                    {!state.userSession ? <SignIn/> : 
                    <Switch>
                        <Route exact path="/search"><ProfileSearchScreen/></Route>
                        <Route exact path={Routes.Home}><Home name="amos"/></Route>
                        <Route path={Routes.About}><About message="About page"/></Route>
                        <Route path={Routes.Profile}><Profile/></Route>
                        <Route path={Routes.Students}><StudentOverview/></Route>
                        <Route path={Routes.Staff}><Staff/></Route>
                        <Route path={Routes.Locations}><Locations/></Route>
                        <Route path={Routes.StaffAssignmentTypes}><StaffAssignmentTypes/></Route>
                        <Route path={Routes.Forbidden} component={Forbidden} />
                        <Route component={Fallback} />
                    </Switch>
                    }
                </div>
            </Container>
        </div>
    );
}
