import { Box, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import * as React from 'react';

import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { GetLocations, Location } from '../../dtos';
import { client } from '../../shared';
import SchoolIcon from '@material-ui/icons/School';

const useStyles = makeStyles({
    table:{
        minWidth: 350,
    },
});

export const LocationsOverview = () =>{
    const classes = useStyles();
    const [locations, setLocations] = useState(new Array<Location>())
    const route = useRouteMatch();

    React.useEffect(() => {
        (async () => {
            setLocations((await client.get(new GetLocations())).locations);
        })();
    }, []); // fires once



    return (
        <div>
            <Typography variant="h2">Locations</Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Location Table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>LOC</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>PsId</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {locations.map((l) => (
                            <TableRow key={l.locationId}>
                                <TableCell>{l.isSchool ? <SchoolIcon/> : null}</TableCell>
                                <TableCell>
                                    <Box fontFamily="Monospace">
                                        {l.code}
                                    </Box>
                                </TableCell>
                                <TableCell>{l.name}</TableCell>
                                <TableCell>{l.powerSchoolId}</TableCell>
                                <TableCell><Link to={`${route.path}/${l.locationId}/edit`}>Edit</Link></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Link  to={`${route.path}/new`}>+New</Link>
        </div>
    );
}