import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@material-ui/core";
import { useState } from "react";
import { CanMembership, Profile, SetCanMembership } from "../../dtos";
import { client } from "../../shared";
import { CanDetailsForm } from "./CanDetailsForm";
import CanMembershipForm from "./CanMembershipForm";

interface CanScreenParams{
    profile:Profile,
    onDone(changed:boolean):void
}
const useStyles = makeStyles( {
    table:{
        minWidth: 350,
    },
    newButton:{
        marginBottom:'5px'
    },
    selectedMembership:{
        backgroundColor:'yellow'
    },

});

const CanScreen = ({profile, onDone}:CanScreenParams)=>{
    const classes = useStyles();
    const [selMembership, setSelMembership] = useState<CanMembership>();
    const p = profile;
    const c = profile.canDetails;

    const mayJoin = p.isActive && !c.isCanMember;
    const handleClick = (membership: CanMembership|undefined) => {
        console.log(membership?.canMembershipId);
        setSelMembership(membership);
        //onEnrollmentClicked(enrollment);
    }

    const handleJoin = async () => {
        try {

            await client.put(new SetCanMembership({
                membership: new CanMembership({
                    profileId:p.profileId
                })
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
        }

    }

    if (selMembership) return (
        <div>
            <CanMembershipForm profile={profile} membership={selMembership} onDone={onDone}/>
            <Button onClick={()=>setSelMembership(undefined)}>Done</Button>
        </div>
    );

    return(
        <div>
            {mayJoin ? <Button onClick={handleJoin}>Join CAN</Button> : null}
            {c.isCanMember ? <Button>Disjoin CAN</Button> : null}
            {c.canStartDate ? <CanDetailsForm profile={profile} onDone={onDone}/> : null}
            {c.canStartDate ? 
            <Paper>
                <Typography component="h4" variant="h4">Memberships</Typography>

                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="CAN Memberships Table">
                        <TableBody>
                            {p.canMemberships.map((m) => (
                                <TableRow
                                    key={m.canMembershipId}
                                    onClick={()=>handleClick(m)}
                                    hover
                                    className={m.canMembershipId === p.canMembershipId ? classes.selectedMembership : undefined}
                                >
                                    <TableCell>
                                        <Typography>
                                            {m.startDate} - {m.endDate}
                                        </Typography><br/>
                                        {m.endNote}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            : null}
            <Button variant="text" color="secondary" onClick={() => onDone(false)}>Back to Profile</Button>

        </div>
    );
}

export default CanScreen