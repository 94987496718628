import { Button, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import * as React from 'react';
import { StaffAssignment } from '../../dtos';
import { LocationsDict } from '../../shared/useLocations';

const useStyles = makeStyles( {
    table:{
        minWidth: 350,
    },
    newButton:{
        marginBottom:'5px'
    },
    selectedAssignment:{
        backgroundColor:'yellow'
    },
});


interface StaffProfileAssignmentListProps {
    selectedAssignmentId: number,
    assignments: StaffAssignment[],
    onAssClicked(assignment:StaffAssignment|undefined):void
}

export const StaffProfileAssignmentList = ({selectedAssignmentId, assignments, onAssClicked}:StaffProfileAssignmentListProps) => {
    const classes = useStyles();
    const assCurrent = assignments.filter(x=>x.isActive);
    const assFormer = assignments.filter(x=>!x.isActive);

    const handleClick = (assignment: StaffAssignment | undefined) => {
        onAssClicked(assignment);
    }

    const curJsx = <>
        <Typography variant="h6">Current Assignments</Typography>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Current Assignments Table">
                <TableHead>
                    <TableRow>
                        <TableCell>Assignment</TableCell>
                        <TableCell>Started</TableCell>
                        <TableCell>EXT</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {assCurrent.map((a) => (
                        <TableRow
                            key={a.staffAssignmentId}
                            onClick={()=>handleClick(a)}
                            hover
                            className={a.staffAssignmentId === selectedAssignmentId ? classes.selectedAssignment : undefined}
                        >
                            <TableCell>{a.title} @{LocationsDict[a.locationId].code}</TableCell>
                            <TableCell>{a.startDate}</TableCell>
                            <TableCell>{a.extension}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>

const forJsx = <>
<Typography variant="h6">Former Assignments</Typography>
<TableContainer component={Paper}>
    <Table className={classes.table} aria-label="Former Assignments Table">
        <TableHead>
            <TableRow>
                <TableCell>Assignment</TableCell>
                <TableCell>Ended</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {assFormer.map((a) => (
                <TableRow
                    key={a.staffAssignmentId}
                    onClick={()=>handleClick(a)}
                    hover
                    className={a.staffAssignmentId === selectedAssignmentId ? classes.selectedAssignment : undefined}
                >
                    <TableCell>{a.title} @{LocationsDict[a.locationId].code}</TableCell>
                    <TableCell>{a.endDate}</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
</TableContainer>
</>


    return(<>
        <Button variant="outlined" color="primary" className={classes.newButton} onClick={()=>{handleClick(undefined)}}>New Assignment</Button>
        {assCurrent.length > 0 ? curJsx : null}
        {assFormer.length > 0 ? forJsx : null}
    </>);
}