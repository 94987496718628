import * as React from 'react'
import SSSelect, { SSSelectItem } from '../SSForms/SSSelect';

const gradeList: Array<SSSelectItem<number>> = [
    { value: 0, label: 'Kindergarten' },
    { value: 1, label: '1st Grade' },
    { value: 2, label: '2nd Grade' },
    { value: 3, label: '3rd Grade' },
    { value: 4, label: '4th Grade' },
    { value: 5, label: '5th Grade' },
    { value: 6, label: '6th Grade' },
    { value: 7, label: '7th Grade' },
    { value: 8, label: '8th Grade' },
    { value: 9, label: '9th Grade' },
    { value: 10, label: '10th Grade' },
    { value: 11, label: '11th Grade' },
    { value: 12, label: '12th Grade' }
];

interface GradeSelectProps{
    grade: number,
    onChange(fieldName:string, value:number):void;
}

export const GradeSelect = ({grade, onChange}:GradeSelectProps) => {

    const handleChange = (field: string, newValue: number ) => {
        onChange(field, newValue);
    }

    return(
        <SSSelect id="grade" label="Grade" value={grade} items={gradeList} onChange={handleChange} />
    );
}

export default GradeSelect;