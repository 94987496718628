import { MenuItem } from '@material-ui/core';
import useLocations, { Locations } from '../../shared/useLocations';
import SSSelect from '../SSForms/SSSelect';

interface LocationSelectProps{
    locationId: number,
    onChange(fieldName:string, value:number):void;
}

export const LocationSelect = ({locationId, onChange}:LocationSelectProps) => {
    const [status] = useLocations();

    const handleChange = (field: string, newValue: number ) => {
        onChange(field, newValue);
    }

    if (status !== 'fetched') return (<div>{status}</div>);
    return(
        <SSSelect id="locationId" label="Location" value={locationId} onChange={handleChange} >
            {Locations.map((l)=>(
                <MenuItem key={l.locationId} value={l.locationId}>{l.name}</MenuItem>
            ))}
        </SSSelect>
    );
}

export default LocationSelect;