import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { ResponseStatus } from './ResponseStatus';

interface SSCheckBoxProps {
    id: string,
    label: string,
    checked: boolean,
    onChange(field:string, value:boolean): void,
    responseStatus:ResponseStatus | null,
}

export const SSCheckBox = (props: SSCheckBoxProps) => {
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.checked;
        props.onChange(props.id, v);
    }
    return (
        <Grid item xs={12}>
        <FormControlLabel
            control={
                <Checkbox 
                    id={props.id}
                    name={props.id}
                    checked={props.checked}
                    onChange = {handleChange}
                />
            }
            label = {props.label}
        />
        </Grid>
    );
};