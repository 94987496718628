import * as React from 'react';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { SelectStaffAssignment, SetStaffAssignment, StaffAssignment } from "../../dtos";
import { client, getTodayAsString } from '../../shared';
import { SSCheckBox, SSFormErrors, SSTextField } from '../SSForms';
import { SSDatePicker } from '../SSForms/SSDatePicker';
import LocationSelect from '../PremadeForms/LocationSelect';
import { StaffAssignmentTypeSelect } from '../PremadeForms/StaffAssignmentTypeSelect';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


interface StaffAssignmentFormProps{
    profileId: number,
    details?: StaffAssignment,
    onDone(changed:boolean):void
}

export const StaffAssignmentForm = ({details, profileId, onDone}: StaffAssignmentFormProps) =>{
    const classes = useStyles();
    const [responseStatus, setResponseStatus] = React.useState(null);

    const [dto, setDto] = React.useState(details ?? new StaffAssignment({staffAssignmentId:0, sortPriority:5, isActive:true, startDate:getTodayAsString()}));
    const [isTransfer, setIsTransfer] = React.useState(false);

    const submit = async () => {
        try {
            setResponseStatus(null);

            await client.put(new SetStaffAssignment({
                profileId: profileId,
                assignment: dto,
                isTransfer
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const doSelect = async() => {
        try {
            setResponseStatus(null);

            await client.put(new SelectStaffAssignment({
                staffAssignmentId:dto.staffAssignmentId
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleCheckboxChange = (field:string, value:boolean) => {
        if(field === "isTransfer"){
            setIsTransfer(value);
        } else {
            setDto(x => ({
                ...x,
                [field]:value
            }));
        }
    }
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value ?? ''
        }));
    }
    const handleNumberChange = (field:string, value:number) => {
        setDto(x => ({
            ...x,
            [field]:value
        }));
    }

    const handleSortChange = (field:string, value:string) => {
        var n = Number(value);
        setDto(o => ({
            ...o,
            [field]:n ? n:5
        }));
    }

    return (
        <>
            <Typography component="h3" variant="h3">Set Staff Assignment</Typography>

            <Button onClick={doSelect}>Set this assignment as Primary</Button>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    {dto.staffAssignmentId !== 0 ? null : 
                        <SSCheckBox id="isTransfer" checked={isTransfer} responseStatus={responseStatus} label="Is this is a transfer?" onChange={handleCheckboxChange} />
                    }
                    <SSFormErrors responseStatus={responseStatus}/>
                    <LocationSelect locationId={dto.locationId} onChange={handleNumberChange}/>
                    <StaffAssignmentTypeSelect assignmentTypeId={dto.staffAssignmentTypeId} onChange={handleNumberChange}/>
                    <SSTextField id="title" value={dto.title} label="Title" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSCheckBox id="certified" checked={dto.certified} responseStatus={responseStatus} label="Certified" onChange={handleCheckboxChange} />
                    <SSCheckBox id="sped" checked={dto.sped} responseStatus={responseStatus} label="Sped" onChange={handleCheckboxChange} />
                    <SSCheckBox id="contracted" checked={dto.contracted} responseStatus={responseStatus} label="Contracted" onChange={handleCheckboxChange} />
                    <SSTextField id="room" value={dto.room} label="Room" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="extension" value={dto.extension} label="Extension" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="directPhone" value={dto.directPhone} label="Direct Phone" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="sortPriority" value={dto.sortPriority.toString()} label="Sort Priority" onChange={handleSortChange} responseStatus={responseStatus}/>

                    <SSDatePicker id="startDate" label="Start Date" value={dto.startDate} onChange={handleTextChange} />
                    <SSCheckBox id="isActive" checked={dto.isActive} responseStatus={responseStatus} label="Active" onChange={handleCheckboxChange} />

                    <SSDatePicker id="endDate" label="End Date" value={dto.endDate} onChange={handleTextChange} />
                    <SSTextField id="endNote" value={dto.endNote} label="End Note" onChange={handleTextChange} responseStatus={responseStatus}/>

                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={() => onDone(false)}>Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}