import * as React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { client, Routes, classNames } from '../../shared';
import { GetStaffAssignmentType, StaffAssignmentType, SetStaffAssignmentType } from '../../dtos';
import { Grid, Button, Typography, makeStyles, Link } from '@material-ui/core';
import { SSFormErrors, SSTextField } from '../SSForms';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

export const SetStaffAssignmentTypeForm = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [responseStatus, setResponseStatus] = useState(null);

    const history = useHistory();
    const { id } = useParams<{id:string}>();
    const [dto, setDto] = useState<StaffAssignmentType>({staffAssignmentTypeId:0, sortOrder:0, name:''})

    React.useEffect(() => {
        if(id!==undefined){
            (async () => {
                var l = (await client.get(new GetStaffAssignmentType({staffAssignmentTypeId:Number(id)}))).staffAssignmentType;
                setDto(l);
            })();
        }
    }, [id]); // fires once

    const submit = async () => {
        try {
            setLoading(true);
            setResponseStatus(null);

            await client.put(new SetStaffAssignmentType({
                staffAssignmentType:dto
            }));

            setLoading(false);
            history.push(Routes.StaffAssignmentTypes);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
            setLoading(false);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value
        }));
    }
    const handleNumberChange = (field:string, value:string) => {
        setDto(o => ({
            ...o,
            [field]:value==='' ? 0 : Number(value)
        }));
    }


    return (
        <>
            <Typography component="h1" variant="h2">Set Location Data</Typography>

            <form className={classNames({error:responseStatus, loading})} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSTextField id="sortOrder" type="number" value={`${dto.sortOrder}`} placeholder="Sort Order" label="Sort Order" onChange={handleNumberChange} responseStatus={responseStatus}/>
                    <SSTextField id="name" value={dto.name} placeholder="Name" label="Name" onChange={handleTextChange} responseStatus={responseStatus}/>
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Link href={Routes.StaffAssignmentTypes} variant="body2">Back to Ass. Types</Link>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}