import * as React from 'react';

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { StaffAssignmentTypesOverview } from "./StaffAssignmentTypesOverview";
import { SetStaffAssignmentTypeForm } from "./SetStaffAssignmentTypeForm";

const StaffAssignmentTypes = () => {
    const route = useRouteMatch();
    return(
        <Switch>
            <Route path={`${route.path}/:id/edit`} component={SetStaffAssignmentTypeForm} />
            <Route path={`${route.path}/new`} component={SetStaffAssignmentTypeForm} />
            <Route component={StaffAssignmentTypesOverview}/>
        </Switch>
    );
}

export default StaffAssignmentTypes