import { Card, CardActionArea, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { ProfileDetails } from '../../dtos';

const useStyles = makeStyles({
    title: {
      fontSize: 18,
    },
    surname:{
        fontWeight: "bold"
    }
  });

interface ProfileDetailsCardProps{
    details: ProfileDetails,
    onClick(): void
}
export const ProfileDetailsCard = ({details, onClick}:ProfileDetailsCardProps) => {
    const classes = useStyles();
    return(
        <Card variant="outlined">
            <CardActionArea onClick={onClick}>
                <CardHeader title="Profile"/>
                <CardContent>
                    <Typography>{details.name} <span className={classes.surname}>{details.surname}</span></Typography>
                    <Typography>{details.fullName}</Typography>
                    <Typography>{details.sex} | {details.birthMonth ? `${details.birthMonth}/${details.birthDay}` : 'no birthday'}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}