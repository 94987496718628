import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import * as React from 'react';

type OptionValue = string | number;

export interface SSSelectItem<T extends OptionValue> {
    label: string;
    value: T;
}


interface SSSelectProps<T extends OptionValue>{
    id: string;
    label: string;
    value: T;
    items?: Array<SSSelectItem<T>>;
    children?: React.ReactNode;
    onChange(fieldName:string, value:T):void;
}

export const SSSelect = <T extends OptionValue>({id, label, value, items, children, onChange}:SSSelectProps<T>) => {
    const labelId = `${id}-helper-label`;

    const handleChange = (event: React.ChangeEvent<{value: unknown}>) => {
        onChange(id, event.target.value as T);
    }
    // console.log(`${id} items/children:`);
    // console.log(items);
    // console.log(children);
    return(
    <Grid item xs={12}>
        <FormControl fullWidth>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                id = {id}
                name = {id}
                labelId={labelId}
                onChange={handleChange}
                value={value}
                required
            >
            { items ? items.map((item)=>(
                <MenuItem key={`${id}-${item.value}`} value={item.value}>{item.label}</MenuItem>
            )) : children
            }
            </Select>
        </FormControl>
    </Grid>
    );
}

export default SSSelect