import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { ResponseStatus } from './ResponseStatus';

interface SSTextFieldProps {
    id: string,
    label: string,
    value: string,
    placeholder?: string,
    onChange(field:string, value:string): void,
    responseStatus:ResponseStatus | null,
    type?:string,
    required?:boolean,
}

export const SSTextField = (props: SSTextFieldProps) => {
    let hasError=false;
    let error='';
    if (props.responseStatus){
        for(let i=0; i < props.responseStatus.errors.length; i++){
            const x = props.responseStatus.errors[i];
            if(x.meta.PropertyName.toLowerCase() === props.id.toLowerCase()){
                hasError=true;
                error=x.message;
                break;
            }
        }
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        props.onChange(props.id, v ?? '');
    }

    return (
        <Grid item xs={12}>
        <TextField 
            variant="filled"
            type= {props.type || "text"}
            id={props.id}
            name={props.id}
            label={props.label}
            value={props.value}
            placeholder={props.placeholder}
            error = {hasError}
            helperText = {error}
            onChange = {handleChange}
            required = {props.required}
            fullWidth
        />
        </Grid>
    );
};
