import { Box, Grid } from '@material-ui/core';
import { ResponseStatus, SSError } from './ResponseStatus';

interface SSFormErrorsProps{
    responseStatus: ResponseStatus | null
}

export const SSFormErrors = ({responseStatus}:SSFormErrorsProps) => {
    if (!responseStatus) return null;

    if (!responseStatus.errors || responseStatus.errors.length === 0){
        return(
            <Box color="red">
               {responseStatus.message} 
            </Box>
        )
    }

    return(
        <Grid item xs={12}>
            <ul>
                {responseStatus.errors.map((e:SSError, index)=>(
                    <li key={index}>
                        {e.fieldName}: {e.message} [{e.meta.PropertyName}]
                    </li>
                ))}
            </ul>
        </Grid>
    );
}
