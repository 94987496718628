import * as React from 'react';
import { StateContext, signout } from '../../shared';
import { LinkButton } from '@servicestack/react';
import { HelloApi } from './HelloApi';
import { Grid } from '@material-ui/core';
import GradeSelect from '../PremadeForms/GradeSelect';
import LocationSelect from '../PremadeForms/LocationSelect';
import { SSDatePicker } from '../SSForms/SSDatePicker';

export const Home = (props:any) => {
    const {state, dispatch} = React.useContext(StateContext);
    const [ grade, setGrade ] = React.useState(0);
    const [ locationId, setLocationId ] = React.useState(9);
    const [ date, setDate ] = React.useState<string>("1978-04-20");

    const handleSignOut = async () => await signout(dispatch);

    const handleChange = (field: string, newValue: number ) => {
        console.log(`Changed: ${field} = ${newValue}`);
        if (field==="grade") setGrade(newValue);
        if (field==="locationId") setLocationId(newValue);
    }
    const handleDateChange = (field: string, value:string) => {
        console.log(`Date Picked: ${value}`);
        setDate(value);
    }


    return (<div className="row justify-content-between">
        <div className="col col-1">
            <i className="svg-home svg-9x" />
        </div>
        <div className="col col-4 mt-4">
            <HelloApi name={props.name} />
        </div>
        <div className="col-md-auto" />
        <div className="col col-4">
            {state.userSession ?
            (<div className="text-right">
                <p className="pt-3">{`Hi ${state.userSession!.displayName}!`}</p>
                <LinkButton onClick={handleSignOut} sm primary>Sign Out</LinkButton>
            </div>) :
            (<div className="text-right">
                <p className="pt-3">You are not authenticated.</p>
                <LinkButton href="/signin" sm primary>Sign In</LinkButton>
                <LinkButton href="/signup" sm outline-secondary className="ms-2">Register New User</LinkButton>
            </div>)}
        </div>
        <Grid container>
            <GradeSelect grade={grade} onChange={handleChange}/>
            <LocationSelect locationId={locationId} onChange={handleChange}/>
            <SSDatePicker id="date" label="Sample Date" value={date} onChange={handleDateChange} />
        </Grid>
    </div>);
}