import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { GetStaffAssignmentList, GetStaffAssignmentListResponse } from '../../dtos';
import { client } from '../../shared';

const useStyles = makeStyles( {
    table:{
        minWidth: 350,
    },
    groupHead:{
        marginTop: 25
    }
});

const StaffLocationList = () => {
    const classes = useStyles();
    const { id } = useParams<{id:string}>();
    const [ dto, setDto ] = useState(new GetStaffAssignmentListResponse());
    const history = useHistory();

    React.useEffect(() => {
        if(id!==undefined){
            (async () => {
                var l = (await client.get(new GetStaffAssignmentList({locationId:Number(id)})));
                setDto(l);
            })();
        }
    }, [id]); // fires once


    const handleClick = (event: React.MouseEvent<unknown>, profileId: number) => {
        console.log(profileId);
        history.push(`/staff/${profileId}`);
    }

    console.log(dto.groups);
    if (dto.groups === undefined) return null;

    return(
        <div>
        <Typography variant="h3">{dto.name} Staff</Typography>

        {dto.groups.map((g)=>(<div key={g.name}>
        <Typography variant="h4" className={classes.groupHead}>{g.name}</Typography>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Location Table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Mug</TableCell>
                        <TableCell>Staff</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {g.staff.map((s) => (
                        <TableRow
                            key={g.name+s.profileId}
                            onClick={(event)=>handleClick(event, s.profileId)}
                            hover
                        >
                            <TableCell>{s.sortPriority === 5 ? "":s.sortPriority}</TableCell>
                            <TableCell><img src={s.mugshotThumbnail ?? '/nomug.jpg'} height="80" alt="mugshot"/></TableCell>
                            <TableCell>
                                <Typography variant="body1">{s.name} {s.surname}</Typography>
                                <br/>
                                <Typography variant="subtitle1">{s.title}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
            
        </div>))}
        </div>

    );
}

export default StaffLocationList;