import * as React from 'react';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { SetStudentEnrollment, StudentEnrollment } from "../../dtos";
import { client } from '../../shared';
import { SSCheckBox, SSFormErrors, SSTextField } from '../SSForms';
import { SSDatePicker } from '../SSForms/SSDatePicker';
import LocationSelect from '../PremadeForms/LocationSelect';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


interface StudentEnrollmentFormProps{
    profileId: number,
    details?: StudentEnrollment,
    onDone(changed:boolean):void
}

export const StudentEnrollmentForm = ({details, profileId, onDone}: StudentEnrollmentFormProps) =>{
    const classes = useStyles();
    const [responseStatus, setResponseStatus] = React.useState(null);

    const [dto, setDto] = React.useState(details ?? new StudentEnrollment({profileId:profileId, grade:0, locationId:0, code:'', endNote:'', startNote:'', startDate:new Date().toISOString().substring(0,10)}));

    const submit = async () => {
        try {
            setResponseStatus(null);

            await client.put(new SetStudentEnrollment({
                profileId: profileId,
                enrollment: dto
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleCheckboxChange = (field:string, value:boolean) => {
        setDto(x => ({
            ...x,
            [field]:value
        }));
    }
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value ?? ''
        }));
    }
    const handleNumberChange = (field:string, value:number) => {
        setDto(x => ({
            ...x,
            [field]:value
        }));
    }

    const handleNullableNumberChange = (field:string, value:string) => {
        var n = Number(value);
        setDto(o => ({
            ...o,
            [field]:n ? n:undefined
        }));
    }

    return (
        <>
            <Typography component="h3" variant="h3">Set Profile Details</Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSTextField id="locationId" value={dto.locationId ? dto.locationId.toString():''} label="School Id" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                    <LocationSelect locationId={dto.locationId} onChange={handleNumberChange}/>
                    <SSTextField id="grade" value={dto.grade ? dto.grade.toString():''} label="Grade" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                    <SSTextField id="code" value={dto.code} label="Enrollment Code" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSDatePicker id="startDate" label="Start Date" value={dto.startDate} onChange={handleTextChange} />
                    <SSCheckBox id="isActive" checked={dto.isActive} responseStatus={responseStatus} label="Active" onChange={handleCheckboxChange} />

                    <SSDatePicker id="endDate" label="End Date" value={dto.endDate} onChange={handleTextChange} />
                    <SSTextField id="endNote" value={dto.endNote} label="End Note" onChange={handleTextChange} responseStatus={responseStatus}/>
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={() => onDone(false)}>Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}