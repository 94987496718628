import * as React from 'react';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Profile, SetProfile } from "../../dtos";
import { client } from '../../shared';
import { SSFormErrors } from '../SSForms';
import { SSDatePicker } from '../SSForms/SSDatePicker';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


interface CanDetailsFormProps{
    profile:Profile,
    onDone(changed:boolean):void
}

export const CanDetailsForm = ({profile, onDone}: CanDetailsFormProps) =>{
    const classes = useStyles();
    const [responseStatus, setResponseStatus] = React.useState(null);

    const [dto, setDto] = React.useState(profile.canDetails);

    const submit = async () => {
        try {
            setResponseStatus(null);

            await client.put(new SetProfile({
                profileId: profile.profileId,
                canDetails: dto
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value ?? ''
        }));
    }

    return (
        <>
            <Typography component="h4" variant="h4">CAN Details</Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSDatePicker id="canStartDate" label="CAN Start Date" value={dto.canStartDate} onChange={handleTextChange} />
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
            </form>
        </>
    );
}