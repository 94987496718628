import { Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { GetStaffOverview, StaffOverviewForLocation } from '../../dtos';
import { client } from '../../shared';
import NewStaffButton from './NewStaffButton';

const useStyles = makeStyles( {
    table:{
        minWidth: 350,
    },
});


export const StaffOverview = () => {
    const classes = useStyles();
    const history = useHistory();
    const [dtos, setDtos] = React.useState(new Array<StaffOverviewForLocation>())

    React.useEffect(() => {
        (async () => {
            setDtos((await client.get(new GetStaffOverview())).overviews);
        })();
    }, []); // fires once
    
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        console.log(id);
        history.push(`/staff/byloc/${id}`);
    }

    return(
<div>
    <Typography variant="h2">Staff by School</Typography>
    <NewStaffButton/>
    <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Location Table">
            <TableHead>
                <TableRow>
                    <TableCell>School</TableCell>
                    <TableCell>Staff</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {dtos.map((l) => (
                    <TableRow
                        key={l.locationId}
                        onClick={(event)=>handleClick(event, l.locationId)}
                        hover
                    >
                        <TableCell>{l.locationName}</TableCell>
                        <TableCell>{l.staffCount}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
</div>
)};