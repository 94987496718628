import { Typography, makeStyles, Avatar, Grid, Button } from '@material-ui/core';
import * as React from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { GetProfile, Profile, StaffAssignment, StudentEnrollment } from '../../dtos';
import { client } from '../../shared';
import useLocations, { LocationsDict } from '../../shared/useLocations';
import CanScreen from '../CAN/CanScreen';
import AmoClipboard from '../SSForms/AmoClipboard';
import { DeactivateProfileForm } from './DeactivateProfileForm';
import { MugshotList } from './MugshotList';
import { P6DetailsCard } from './P6DetailsCard';
import { P6DetailsForm } from './P6DetailsForm';
import { ProfileDetailsCard } from './ProfileDetailsCard';
import { ProfileDetailsForm } from './ProfileDetailsForm';
import { StaffAssignmentForm } from './StaffAssignmentForm';
import { StaffProfileAssignmentList } from './StaffProfileAssignmentList';
import { StudentEnrollmentForm } from './StudentEnrollmentForm';
import { StudentEnrollmentsList } from './StudentEnrollmentsList';

const useStyles = makeStyles( {
    table:{
        minWidth: 350,
    },
    mugshot:{
        objectPosition: "center 25%",
    },
    avatar:{
        width: "80px",
        height: "80px"
    },
    detailcard:{
        padding: "5px"
    },
    deactivateButton:{
        color: "red",
        marginTop: "15px"
    },
});


export const ProfileOverview = () => {
    const classes = useStyles();
    const { profileId } = useParams<{profileId:string, enrollmentId:string}>();
    const [changeCount, setChangeCount] = React.useState(0);
    const [locationStatus] = useLocations();

    const history = useHistory();
    const route = useRouteMatch();

    const [dto, setDto] = React.useState<Profile>();
    const [curEnrollment, setCurEnrollment] = React.useState<StudentEnrollment>();
    const [curAssignment, setCurAssignment] = React.useState<StaffAssignment>();

    React.useEffect(() => {
        (async () => {
            setDto((await client.get(new GetProfile({ profileId: Number(profileId), includeSchedule:true }))).profile);
        })();
    }, [profileId, changeCount]); // fires once


    const handleAssClick = (assignment: StaffAssignment|undefined) => {
        setCurAssignment(assignment);
        history.push(`${route.url}/assignment`);
    }
    const handleEnrollmentClick = (enrollment: StudentEnrollment|undefined) => {
        setCurEnrollment(enrollment);
        history.push(`${route.url}/enrollment`);
    }


    if(!dto) return null;
    if(locationStatus !== 'fetched') return null;

    console.log('Profile:')
    console.log(dto);

    const p = dto;

    const handleSubformDone = (changed: boolean) => {
        console.log(`Done: Changed? ${changed}`);
        if (changed)
            setChangeCount(changeCount+1);
        history.push(`${route.url}/${profileId}`);
    }
    return(
<div>
    <Typography variant="h2">{p.profileType} Profile</Typography>
    <Grid container>
        <Grid item xs={3}>
            <Avatar 
                className={classes.avatar} classes={{img:classes.mugshot}} 
                src={p.mugshot?.thumbnailUrl ?? `/nomug.jpg`} 
                alt={`${p.mugshot?.dateTaken} ${p.mugshot?.photographer}`}
                onClick={()=>history.push(`${route.url}/mugshots`)}/>
        </Grid>
        <Grid item xs={9}>
            <Typography variant="h4">{p.details.honorific} {p.details.name} {p.details.surname}</Typography>
            {p.profileType !== 'Staff' ? null:
                <div>{p.isActive ? 'Current':'Former'} {p.staffAssignment.title} @{LocationsDict[p.staffAssignment.locationId].code}</div>
            }
            {p.profileType !== 'Student' ? null:
                <div>{p.isActive ? 'Current':'Former'} Grade {p.studentEnrollment.grade} @{LocationsDict[p.studentEnrollment.locationId].code} [{p.p6Details.p6Class}]</div>
            }
            {p.canDetails.isCanMember ? 
                <Typography onClick={()=>history.push(`${route.url}/can`)}>CAN Member since {p.canDetails.canStartDate}</Typography>
            : p.canDetails.canStartDate ?
                <Typography onClick={()=>history.push(`${route.url}/can`)}>Former CAN Member</Typography>
            :
                <Typography onClick={()=>history.push(`${route.url}/can`)}>Future CAN Member</Typography>
            }
        </Grid>
    </Grid>

    

    <Switch>
        <Route path={`${route.path}/details`}>
            <ProfileDetailsForm profileId={Number(profileId)} details={p.details} onDone={handleSubformDone} />
        </Route>
        <Route path={`${route.path}/p6details`}>
            <P6DetailsForm profileId={Number(profileId)} details={p.p6Details} profileType={p.profileType} onDone={handleSubformDone} />
        </Route>
        <Route path={`${route.path}/can`}>
            <CanScreen profile={p} onDone={handleSubformDone} />
        </Route>
        <Route path={`${route.path}/enrollment/`}>
            <StudentEnrollmentForm details={curEnrollment} profileId={Number(profileId)} onDone={handleSubformDone} />
        </Route>
        <Route path={`${route.path}/assignment/`}>
            <StaffAssignmentForm details={curAssignment} profileId={Number(profileId)} onDone={handleSubformDone} />
        </Route>
        <Route path={`${route.path}/mugshots/`}>
            <MugshotList selectedMugshotId={p.mugshot?.mugshotId ?? 0} mugshots={p.mugshots} onDone={handleSubformDone}/>
        </Route>
        <Route path={`${route.path}/deactivate/`}>
            <DeactivateProfileForm profileId={p.profileId} endNote={p.endNote} endDate={p.endDate} onDone={handleSubformDone}/>
        </Route>
        <Route>
            <div>
                <AmoClipboard title="Name" value={p.details.name + ' ' + p.details.surname}/>
                <AmoClipboard title="Pid" value={p.profileId}/>
                <AmoClipboard title="Email" value={p.p6Details.p6Username + '@park6.org'}/>
                <AmoClipboard title="Creds" value={p.p6Details.p6PsWebUser+p.p6Details.p6PsWebPass}/>
                <AmoClipboard title="Sid" value={p.p6Details.p6StudentNumber?.toString() ?? ''}/>
                <ProfileDetailsCard details={p.details} onClick={()=>history.push(`${route.url}/details`)}/>
                <P6DetailsCard details={p.p6Details} profileType={p.profileType} onClick={()=>history.push(`${route.url}/p6details`)}/>
                {p.profileType !== 'Staff' ? null:
                    <StaffProfileAssignmentList selectedAssignmentId={dto.staffAssignment.staffAssignmentId} assignments={dto.staffAssignments} onAssClicked={handleAssClick}/>
                }
                {p.profileType !== 'Student' ? null:
                    <StudentEnrollmentsList profile={p} onEnrollmentClicked={handleEnrollmentClick}/>
                }
                {p.isActive ? 
                <Button variant="outlined" className={classes.deactivateButton} fullWidth onClick={() => history.push(`${route.url}/deactivate`)}>DEACTIVATE PROFILE</Button>
                :
                <div>To reactivate, create new {p.profileType === 'Student' ? 'enrollment':'assignment'}.</div>}
            </div>
        </Route>
    </Switch>
</div>
)};