import * as React from 'react';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { ProfileDetails, SetProfile } from "../../dtos";
import { client } from '../../shared';
import { SSFormErrors, SSTextField } from '../SSForms';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


interface ProfileDetailsFormProps{
    profileId: number,
    details: ProfileDetails,
    onDone(changed:boolean):void
}

export const ProfileDetailsForm = ({details, profileId, onDone}: ProfileDetailsFormProps) =>{
    const classes = useStyles();
    const [responseStatus, setResponseStatus] = React.useState(null);

    const [dto, setDto] = React.useState(details);

    const submit = async () => {
        try {
            setResponseStatus(null);

            await client.put(new SetProfile({
                profileId: profileId,
                profileDetails: dto
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value ?? ''
        }));
    }
    const handleNullableNumberChange = (field:string, value:string) => {
        var n = Number(value);
        setDto(o => ({
            ...o,
            [field]:n ? n:undefined
        }));
    }

    return (
        <>
            <Typography component="h3" variant="h3">Set Profile Details</Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSTextField id="honorific" value={dto.honorific} placeholder="Honorific" label="Honorific" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="name" value={dto.name} placeholder="Name" label="Name" onChange={handleTextChange} required responseStatus={responseStatus}/>
                    <SSTextField id="surname" value={dto.surname} placeholder="Surname" label="Surname" onChange={handleTextChange} responseStatus={responseStatus}/>
                    <SSTextField id="fullName" value={dto.fullName} placeholder="Full Name" label="Full Name" onChange={handleTextChange} responseStatus={responseStatus}/>

                    <SSTextField id="sex" value={dto.sex} placeholder="Sex" label="Sex (Male/Female)" onChange={handleTextChange} responseStatus={responseStatus}/>

                    <SSTextField id="birthMonth" value={dto.birthMonth ? dto.birthMonth.toString():''} placeholder="Birth Month" label="Birth Month" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                    <SSTextField id="birthDay" value={dto.birthDay ? dto.birthDay.toString():''} placeholder="Birth Day" label="Birth Day" onChange={handleNullableNumberChange} responseStatus={responseStatus}/>
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={() => onDone(false)}>Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}