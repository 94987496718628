import * as React from 'react';

import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';

import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { GetStaffAssignmentTypes, StaffAssignmentType } from '../../dtos';
import { client } from '../../shared';

const useStyles = makeStyles({
    table:{
        minWidth: 350,
    },
});

export const StaffAssignmentTypesOverview = () =>{
    const classes = useStyles();
    const [dtos, setDtos] = useState(new Array<StaffAssignmentType>())
    const route = useRouteMatch();

    React.useEffect(() => {
        (async () => {
            setDtos((await client.get(new GetStaffAssignmentTypes())).staffAssignmentTypes);
        })();
    }, []); // fires once



    return (
        <div>
            <Typography variant="h2">Locations</Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Location Table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Sort</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dtos.map((l) => (
                            <TableRow key={l.staffAssignmentTypeId}>
                                <TableCell>{l.sortOrder}</TableCell>
                                <TableCell>{l.name}</TableCell>
                                <TableCell><Link to={`${route.path}/${l.staffAssignmentTypeId}/edit`}>Edit</Link></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Link  to={`${route.path}/new`}>+New</Link>
        </div>
    );
}