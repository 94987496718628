import { Button, IconButton, makeStyles, Toolbar} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import * as React from 'react';
import { Routes } from '../shared';
import MenuIcon from "@material-ui/icons/Menu";
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ProfileSearchBox from './Search/ProfileSearchBox';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

export const AppNavBar = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" area-label="menu">
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        AMO
                    </Typography>

                    <Button component={Link} to={Routes.Students} color="inherit">
                        Students
                    </Button>
                    <Button component={Link} to={Routes.Staff} color="inherit">
                        Staff
                    </Button>
                    <Button component={Link} to={Routes.Locations} color="inherit">
                        Locations
                    </Button>
                    <Button component={Link} to={Routes.StaffAssignmentTypes} color="inherit">
                        Ass. Types
                    </Button>
                    <ProfileSearchBox/>
                </Toolbar>
            </AppBar>
    </div>
)};
