const copyToClipboard: React.FormEventHandler<HTMLInputElement> = (event) => {
    const el = document.createElement('textarea');
    el.value = event.currentTarget.value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    console.log(`Copied ${event.currentTarget.value} to clipboard `);
};

interface AmoClipboardProps {
    title: string;
    value: string|number;
}

const AmoClipboard = ({ title, value }:AmoClipboardProps) => {
    return (
        <div>
            <input value={value} onClick={copyToClipboard} readOnly /> : {title}
        </div>
    );
}

export default AmoClipboard;