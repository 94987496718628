import * as React from 'react';
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { SetCanMembership, CanMembership, Profile } from "../../dtos";
import { client, getTodayAsString } from '../../shared';
import { SSCheckBox, SSFormErrors, SSTextField } from '../SSForms';
import { SSDatePicker } from '../SSForms/SSDatePicker';

const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}));


interface CanMembershipFormProps{
    profile: Profile,
    membership?: CanMembership,
    onDone(changed:boolean):void
}

export const CanMembershipForm = ({membership, profile, onDone}: CanMembershipFormProps) =>{
    const classes = useStyles();
    const [responseStatus, setResponseStatus] = React.useState(null);

    const [dto, setDto] = React.useState(membership ?? new CanMembership({profileId:profile.profileId, endNote:'', startNote:'', startDate:getTodayAsString()}));

    const submit = async () => {
        try {
            setResponseStatus(null);

            await client.put(new SetCanMembership({
                membership: dto
            }));

            onDone(true);
        } catch (e) {
            console.log(e.responseStatus);
            setResponseStatus(e.responseStatus || e);
        }
    }

    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => { e.preventDefault(); await submit(); };
    const handleCheckboxChange = (field:string, value:boolean) => {
        setDto(x => ({
            ...x,
            [field]:value
        }));
    }
    const handleTextChange = (field:string, value:string) => {
        setDto(x => ({
            ...x,
            [field]:value ?? ''
        }));
    }

    return (
        <>
            <Typography component="h3" variant="h3">Set Profile Details</Typography>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <SSFormErrors responseStatus={responseStatus}/>
                    <SSDatePicker id="startDate" label="Start Date" value={dto.startDate} onChange={handleTextChange} />
                    <SSCheckBox id="hasPermissionForm" checked={dto.hasPermissionForm} responseStatus={responseStatus} label="Has Perm Form" onChange={handleCheckboxChange} />
                    <SSCheckBox id="isActive" checked={dto.isActive} responseStatus={responseStatus} label="Active" onChange={handleCheckboxChange} />
                    <SSDatePicker id="endDate" label="End Date" value={dto.endDate} onChange={handleTextChange} />
                    <SSTextField id="endNote" value={dto.endNote} label="End Note" onChange={handleTextChange} responseStatus={responseStatus}/>
                    {dto.isActive ? 
                        null 
                    : 
                        <SSTextField id="disjoinType" value={dto.disjoinType} label="Disjoin Type" onChange={handleTextChange} responseStatus={responseStatus}/>
                    }
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>Submit</Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <Button variant="text" color="secondary" onClick={() => onDone(false)}>Cancel</Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

export default CanMembershipForm