import { Button, Card, CardContent, CardHeader, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@material-ui/core';
import { Profile, StudentEnrollment } from '../../dtos';
import { LocationsDict } from '../../shared/useLocations';

const useStyles = makeStyles( {
    table:{
        minWidth: 350,
    },
    newButton:{
        marginBottom:'5px'
    },
    selectedEnrollment:{
        backgroundColor:'yellow'
    },
});


interface StudentEnrollmentsListProps {
    profile: Profile,
    onEnrollmentClicked(enrollment:StudentEnrollment|undefined):void
}

export const StudentEnrollmentsList = ({profile, onEnrollmentClicked}:StudentEnrollmentsListProps) => {
    const classes = useStyles();
    const p = profile;
    const enrollments = p.studentEnrollments;

    const handleClick = (enrollment: StudentEnrollment|undefined) => {
        //console.log(staffAssignmentId);
        onEnrollmentClicked(enrollment);
    }
 

    return(
        <Card variant="outlined">
            <CardHeader title="Student Data"/>
            <CardContent>
            <Typography variant="h6">Schedule</Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Former Assignments Table">
                    <TableBody>
                        {p.studentSchedules.map((s) => (
                            <TableRow
                                key={s.expression}
                                hover
                            >
                                <TableCell>
                                    <Typography>
                                    {s.expression} - {s.teacherSurname} ({s.courseName})
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <hr/>


            <Typography variant="h6">Student ID Card</Typography>
                {p.studentIdCard ?
                    <p>Student ID Card issued on {p.studentIdCard.issueDate} ({p.studentIdCard.issueReason})</p>
                :
                    <p>No Student ID Card</p>
                }
            <hr/>


            <Typography variant="h6">Enrollments</Typography>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="Former Assignments Table">
                    <TableBody>
                        {enrollments.map((e) => (
                            <TableRow
                                key={e.studentEnrollmentId}
                                onClick={()=>handleClick(e)}
                                hover
                                className={e.studentEnrollmentId === profile.studentEnrollmentId ? classes.selectedEnrollment : undefined}
                            >
                                <TableCell>
                                    <Typography>
                                    Grade {e.grade} @{LocationsDict[e.locationId].code}
                                    </Typography><br/>
                                    {e.startDate} - {e.endDate}<br/>
                                    {e.endNote}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" color="primary" className={classes.newButton} onClick={()=>{handleClick(undefined)}}>New Enrollment</Button>
            </CardContent>
        </Card>
    );
}